// Import all of Bootstrap's CSS
@import "bootstrap/scss/bootstrap";
$base-color: #289dbf;
$base-color--darker: #2288a5;
$base-font-color: #fff;
$base-font-color--black: #000;

$font-general: "Open Sans", "Sans Serif";
$base-color--grey: #ededed;

:root {
  --bs-body-font-family: "Open Sans", "Sans Serif";
  --bs-navbar-toggler-border-color: #289dbf !important;
}
html {
  font-family: $font-general;
  a,
  a:visited,
  a:focus {
    font-family: $font-general;
  }
}
header {
  background-color: $base-color;
  color: $base-font-color;
}
.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: $base-color;
  background-color: transparent;
  border: none;
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none;
}
.tinni--color {
  color: $base-color !important;
}
.navbar {
  background-color: $base-color--grey;
  color: $base-font-color--black;
}
.dropdown-menu {
  background-color: $base-color;
  a {
    color: $base-font-color;
  }
}
.section--top {
  background: url(../images/fondo-top--home.jpg) no-repeat center -220px,
    linear-gradient(#fff 0%, #e7e7e7 74%, #ddd 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  //   background-color: #cccccc;
}

.h1--home {
  color: $base-color;
  font-weight: bold;
  font-size: 3rem;
}
h1 {
  font-size: 1.5rem;
}
.underline {
  text-decoration: underline !important;
  text-decoration-color: $base-color !important;
  text-underline-offset: 0.55em;
  line-height: 2.79rem;
  sup {
    position: initial !important;
    font-size: 0.75em !important;
    line-height: 2.7rem;
    vertical-align: bottom;
  }
}
.row--icons {
  img {
    width: 75%;
  }
}
.overline {
  text-decoration: overline;
  text-decoration-color: $base-color;
  text-underline-offset: 2.35em;
  text-decoration-thickness: 0.1em;
  // font-size: 1rem !important;
  line-height: 2.23rem;
}

.sectionTitle {
  color: $base-color;
  font-size: 1.5rem;
  font-weight: bold;
}
.flex-shrink-0 {
  min-height: 10rem;
}
.evidencia-intro {
  background-color: $base-color;
  color: $base-font-color;
  padding: 0.5rem;
  text-transform: uppercase;
  font-size: 1.3rem;
  line-height: 2.4rem;
}
thead {
  tr th {
    background-color: #68bad2 !important;
    color: #fff !important;
    width: 50%;
    border: 2px solid white;
  }
}
tbody {
  tr th,
  tr td {
    background-color: $base-color--grey !important;
    border: 2px solid white;
    vertical-align: middle !important;
  }
}

.btn-cinfa {
  --bs-btn-bg: #289dbf;
  --bs-btn-border-color: #289dbf;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2288a5;
  /* --bs-btn-hover-border-color: #0a58ca; */
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2288a5;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: $base-color;
  --bs-btn-disabled-border-color: #0d6efd;
  --bs-btn-color: #fff;
  --bs-btn-padding-x: 2rem;
  --bs-btn-border-radius: 1rem;
  text-transform: uppercase;
}
// ul {
//   list-style: none; /* Remove default bullets */
// }

// ul li::before {
//   content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
//   color: $base-color; /* Change the color */
//   font-weight: bold; /* If you want it to be bold */
//   font-size: 1.5rem;
//   display: inline-block; /* Needed to add space between the bullet and the text */
//   width: 1em; /* Also needed for space (tweak if needed) */
//   margin-left: -1em; /* Also needed for space (tweak if needed) */
// }

li::marker {
  color: $base-color;
  font-size: 1.5rem;
}
.notaPie {
  font-size: 0.7em;
}
p.notaPie {
  margin-bottom: 0rem;
  a,
  a:hover,
  a:visited {
    color: var(--bs-body-color);
    text-decoration: none;
  }
}

.faqTittle {
  padding: 1.5rem 1.2rem 0.5rem 1.2rem;
  border: 1px solid $base-color;
  font-weight: bold;
  color: $base-color;
  font-size: 1.2rem;
}
.arrowDown {
  width: 0;
  height: 0;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  border-top: 1rem solid $base-color;
  float: right;
}
footer {
  background-color: $base-color !important;
  color: $base-font-color;
  a,
  a:visited,
  a:focus {
    color: $base-font-color;
    font-family: $font-general;
    text-decoration: none;
  }
}
#btn-back-to-top {
  background-color: $base-color !important;
  border-color: $base-color;
  position: fixed;
  bottom: 8rem;
  right: 3rem;
  display: none;
}
.fontawesomesvg {
  width: 1em;
  height: 1em;
  vertical-align: -0.125em;
  color: #fff;
}

.twolines {
  text-decoration-line: underline;
  text-decoration-color: $base-color;
  text-underline-offset: -2rem;
  // line-height: 3rem;
  sup {
    font-size: 1rem;
    text-underline-offset: -1rem;
  }
}

.tresline:before,
.tresline:after {
  content: "";
  display: block;
  margin: auto;
  height: 3px;
  width: 50%;
  background: blue;
}

@media (max-width: 768px) {
  .ingredients--immage {
    width: 25% !important;
  }
}
